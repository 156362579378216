.acao{
  display: flex;
  flex-flow: row wrap;
  .v-card__actions {
    text-align: center;
  }
  &-content {
    text-align: center !important;
    height: 100%;
    // margin: 0px;
    // width: calc(100%/10);

    .acao-item {
      height: 100%;
    }
  }
  .v-card {
    // background-color: rgba(0, 0, 0, 0.1);
  }
  &-item {
    // margin: 5px;
    // border: 1px solid white;
  }
  &-title {
    font-size: 14px;
    justify-content: center;
    color: #6d7278;
    text-transform: uppercase;
    font-weight: bold;
  }

  &-boxs {
    @media (min-width: 1081px) {
      width: 12.7%;
      margin-right: 1.8%;
      margin-bottom: 25px;

      &:nth-child(7n) {
        margin-right: 0;
      }
    }

    
    @media (min-width: 671px) and (max-width: 1080px) {
      width: 23.6%;
      margin-right: 1.8%;
      margin-bottom: 20px;

      &:nth-child(4n) {
        margin-right: 0;
      }
    }

    @media (min-width: 531px) and (max-width: 670px) {
      width: 31.3%;
      margin-right: 3%;
      margin-bottom: 20px;

      &:nth-child(3n) {
        margin-right: 0;
      }
    }

    @media (max-width: 530px) {
      width: 47.5%;
      margin-right: 5%;
      margin-bottom: 20px;

      &:nth-child(2n) {
        margin-right: 0;
      }
    }

    .v-card {
      padding: 15px 10px;
      word-wrap: break-word;
      height: 100%;
      display: flex;
      justify-content: center;
    }

    .v-card__actions {
      padding: 0;
    }
  }
}